import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getFaqCategorys() {
      return new Promise((resolve, reject) => {
        axios.get('faq')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getFaqAnswer(context, titulo) {
      return new Promise((resolve, reject) => {
        axios.get(`faq/${titulo}`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
