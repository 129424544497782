<template>
  <div
    id="knowledge-base-article"
  >
    <b-row>
      <b-col
        order="1"
        order-md="2"
      >
        <b-card>

          <!-- Title -->
          <b-card-title class="mb-1">
            <feather-icon
              icon="ArrowRightCircleIcon"
              size="21"
            />

            {{ data.pergunta }}
          </b-card-title>
          <!-- Content -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            :class="`question-content d-flex justify-content-${windowWidth>=576 ? 'start' : 'center'}`"
            v-html="data.resposta"
          />
          <!-- eslint-enable -->
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle,
} from 'bootstrap-vue'
import store from '@/store'
import faqStoreModule from './faqStoreModule'

const FAQ_SPOT_STORE_MODULE_NAME = 'faq-spot'

export default {

  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
  },

  data() {
    return {
      data: [],
    }
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  created() {
    if (!store.hasModule(FAQ_SPOT_STORE_MODULE_NAME)) store.registerModule(FAQ_SPOT_STORE_MODULE_NAME, faqStoreModule)
    this.fetchData(this.$route.params.slugAnswer)
  },

  beforeDestroy() {
    if (store.hasModule(FAQ_SPOT_STORE_MODULE_NAME)) store.unregisterModule(FAQ_SPOT_STORE_MODULE_NAME)
  },

  methods: {
    fetchData(titulo) {
      store
        .dispatch('faq-spot/getFaqAnswer', titulo)
        .then(response => {
          this.data = response.data
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
